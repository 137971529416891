.top-bar {
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url(../../assets/complete/arrows.png);
  background-size: auto 40%;

  img {
    width: 50%;
    margin: 0 auto;
  }
}

.text-gradient {
  background: linear-gradient(90deg, #fff, rgba(231, 165, 0, .4));
  padding: 2px;
  border-radius: 5px;
}

.graph-container {
  overflow: hidden;

  .estimated_dates_wrap {
    margin-top: 1rem;

    .current_date {
      width: 50%;
      float: left;
      text-align: left;
      padding-left: 15px;
      padding-bottom: 10px;
    }

    .goal_dates {
      width: 50%;
      float: left;
      text-align: right;
      padding-right: 15px;
      padding-bottom: 10px;
    }

    .current_date span,
    .goal_dates span {
      background: #222;
      padding: 2px 5px;
      border-radius: 5px;
      font-size: 0.9rem;
      color: white;
    }
  }

  .svg-graph {
    width: 100%;
    height: auto;

    .verticalYJMM {
      stroke-width: 1.5px;
      stroke-dasharray: 0 3;
    }

    .svgText {
      text-anchor: middle;
      stroke-width: 1.419;
      font-weight: 700;

      &.cSad {
        fill: #e7a500;
      }

      &.cHappy {
        fill: #e7a500;
      }
    }

    .face_1 {
      transform: matrix(0.75, 0, 0, 0.75, 17.845251, 13.566498);
    }

    .face_2 {
      transform: matrix(0.75, 0, 0, 0.75, 124.76877, 58.71051);
    }
  }

  .svg-humanbody {
    background-color: #1e1e1e;
    padding: 1.5rem;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
}

.pulse {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  background: #e7a500;
  border: 2px solid #e7a500;
  animation: pulse 2s infinite;
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(93, 172, 107, .4);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(45, 120, 247, 0);
  }
}

.primary-btn {
  border-color: #e7a500;;
  background-color: #e7a500;
  font-weight: bold;
}

@media only screen and (max-width: 786px) {
  .top-header {
    line-height: 2.2rem !important;
  }
}
