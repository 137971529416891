#hero-3d {
    height: 100%;
    width: 100%;

    model-viewer {
        width: 100%;
        height: 300px;
        --progress-bar-color: none;
        --progress-bar-height: 0px;
    }
}


.circle {
    width: 150px;
    height: 150px;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    border-radius: 50%;
    padding: 2rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    img {
        margin-bottom: 0.5rem;
        max-height: 70%;
    }

    &.circle-male {
        background: linear-gradient(180deg, #91e7da 0%, #2da2d7 100%);
        animation: pulse-male 2s infinite;
    }

    &.circle-female {
        background: linear-gradient(180deg, #ef888c 0%, #cf47c6 100%);
        animation: pulse-female 2s infinite;
    }

    @keyframes pulse-male {
        0% {
            box-shadow: 0 0 0 0 rgba(45, 120, 247, 0.4);
        }
        100% {
            box-shadow: 0 0 0 10px rgba(45, 120, 247, 0);
        }
    }

    @keyframes pulse-female {
        0% {
            box-shadow: 0 0 0 0 rgb(239, 136, 140, 0.4);
        }
        100% {
            box-shadow: 0 0 0 10px rgb(239, 136, 140, 0);
        }
    }
}

.header {
    background-image: url(../../assets/home/hero.jpg);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    height: 50vh;

    @media only screen and (max-width: 767px) {
        background-image: url(../../assets/home/hero-mobile.jpg);
        background-position: center bottom;
        height: 45vh;
    }
}
